<template>
  <div>
    <table-server-side-v-1-widget
      :title="`ข้อมูลผู้ใช้งานประเทศ ${$route.params.code}`"
      :url="url"
      :columns="cColumns"
      :actions="actionsTable"
      @clickView="onTableClickView"
    >
      <template #custom_country_code="{row}">
        <b-img :src="row.country_flags_url" :alt="`${row.country_flags_url} country image`" class="mr-50" height="30" />
      </template>
    </table-server-side-v-1-widget>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      url: '',
      actionsTable: [{ keyEmit: 'clickView', label: 'ดูเพิ่มเติม', iconName: 'EyeIcon' }],
    }
  },
  computed: {
    cColumns() {
      return [
        // {
        //   label: 'วันที่',
        //   field: 'created_at',
        //   formatFn: value => this.$date(value).format('YYYY-MM-DD HH:mm:ss'),
        //   tdClass: 'text-center',
        //   thClass: 'text-center',
        // },
        {
          label: '',
          field: 'custom_country_code',
          type: 'custom',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable: false,
          filterable: false,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'อีเมล',
          field: 'email',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'ชื่อ-นามสกุล',
          field: 'full_name',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          label: 'เข้าสู่ระบบผ่าน',
          field: 'provider',
          tdClass: 'text-center',
          thClass: 'text-center',
          // filterable: false,
          // filterOptions: {
          //   enabled: false,
          // },
        },
      ]
    },
  },
  created() {
    this.url = `api/admin/find-in-country/${this.$route.params.code}`
  },
  methods: {
    async onTableClickView(data) {
      // console.log('onTableClickView', data.id)
      this.$router.push({ name: 'userManagement-user-info', params: { userId: data.id } })
    },
  },
}
</script>

<style lang="scss" scoped></style>
